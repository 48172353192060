<template>
	<div>
		<h1>Loading...</h1>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend( {
} );
</script>
